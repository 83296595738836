/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'mojitofont';
  src: url('sfprodisplaymedium-webfont.woff2') format('woff2'), url('sfprodisplaymedium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mojitofont-normal';
  src: url('SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'mojitofont Semibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('mojitofont Semibold Italic'), url('SFPRODISPLAYSEMIBOLDITALIC.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  src: local('SF Pro Display REGULAR'), url('SF-Pro-Display-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display Bold';
  font-style: normal;
  font-weight: bold;
  src: local('SF Pro Display Bold'), url('SF-Pro-Display-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display Black';
  font-style: normal;
  font-weight: bold;
  src: local('SF Pro Display black'), url('SF-Pro-Display-Black.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'mojitofont-bold';
  font-style: normal;
  font-weight: normal;
  src: local('mojitofont Bold'), url('SFPRODISPLAYBOLD.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'mojitofont Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local('mojitofont Heavy Italic'), url('SFPRODISPLAYHEAVYITALIC.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'mojitofont rounded';
  font-style: normal;
  font-weight: normal;
  src: local('mojitofont rounded'), url('SF-Pro-Rounded-Regular.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'Aqum two small';
  src: local('Aqum-2-Classic.otf'), url('Aqum-2-Classic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'Aqum two';
  src: local('Aqum-2-Small.otf'), url('./Aqum2.ttf') format('truetype');
  font-display: swap;
}
